import React from "react"
import { numberOfGuesses } from "./Constants"

type ItemProps = {
	scoreName: string
	scoreValue: number | React.ReactElement
	altColor?: string
	highScore?: boolean
}
export const Item = ({
	scoreName,
	scoreValue,
	altColor = "blue",
	highScore,
}: ItemProps) => (
	<div
		className="Scoreboard"
		style={{
			display: "flex",
			flex: 1,
			flexDirection: "column",
			justifyContent: "center",
			margin: "0 var(--half) var(--single)",
		}}>
		<h3>{scoreName}</h3>
		<div
			className="Scores"
			style={{
				color: `var(--${highScore ? "pink" : altColor})`,
				fontFamily: "var(--font2)",
				fontSize: "26px",
				fontWeight: "bold",
				letterSpacing: "4px",
				textShadow: "var(--lightgray) 2px 2px 0",
			}}>
			{typeof scoreValue === "number"
				? scoreValue.toLocaleString()
				: scoreValue}
		</div>
	</div>
)

type ScoreboardProps = {
	round: number
	score: number
	wrong: number
	highScore: number
	useStaticQuery: string
	isQOTD: boolean
}
const Scoreboard = ({
	round,
	score,
	wrong,
	highScore,
	useStaticQuery,
	isQOTD,
}: ScoreboardProps) => (
	<>
		<div style={{ display: "flex", justifyContent: "center" }}>
			<div
				style={{
					display: "flex",
					justifyContent: "space-evenly",
					marginTop: "var(--single)",
					maxWidth: "700px",
					width: "100%",
				}}>
				{!useStaticQuery && !isQOTD && (
					<Item scoreName="Round" scoreValue={round} />
				)}
				<Item scoreName="Score" scoreValue={score} />
				{!useStaticQuery && (
					<Item
						altColor={wrong === numberOfGuesses ? "red" : undefined}
						scoreName="Guesses"
						scoreValue={numberOfGuesses - wrong}
					/>
				)}
			</div>
		</div>

		{!useStaticQuery && (
			<Item highScore scoreName="High Score" scoreValue={highScore} />
		)}
	</>
)
export default Scoreboard
